import * as React from 'react'
import StarImage from "../images/general/star.svg"
import {graphql, useStaticQuery} from "gatsby";

function FiveStars(){
    return(
        <div className={"flex flex-row p-2"}>
            <img className={"h-7"} src={StarImage} alt={"Star icon"} />
            <img className={"h-7"} src={StarImage} alt={"Star icon"} />
            <img className={"h-7"} src={StarImage} alt={"Star icon"} />
            <img className={"h-7"} src={StarImage} alt={"Star icon"} />
            <img className={"h-7"} src={StarImage} alt={"Star icon"} />
        </div>
    )
}

function SingleReview(props){
    return(
        <div className={"border rounded p-4 m-2"}>
            <div className={"flex justify-between"}>
                <div>
                    <h1 className={"text-xl font-bold text-center font-black/90"}>{props.person}</h1>
                    <p className={"text-gray-500 text-center"}>{props.date}</p>
                </div>

                <div>
                    <FiveStars/>
                </div>
            </div>
            <p className={"leading-loose p-2 pt-1 mt-1 border-t"}>{props.children}</p>
        </div>
    )
}

function ReviewSection(){
    const data = useStaticQuery(graphql`
  query {
    allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Contact_Details {
                Address
                Email
                Hours
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
            Reviews {
                Name
                Description
                Date
            }
        }
    }
}
}

`)
    const WebsiteContent = data.allDataJson.edges[0].node

    return(
        <div className={"dark:bg-gray-900 dark:text-white transition duration-500"}>
            <h1 className={"text-6xl font-bold text-center sm:py-32 py-20 dark:bg-gray-900 dark:text-white transition duration-500"}>What Our <span className={"text-main-color-500"}>Customers</span> Say About Us</h1>
            <div className={"max-w-5xl mx-auto grid grid-cols-1 sm:grid-cols-2 py-10 gap-10"}>
                {
                    WebsiteContent.Reviews.map((node, i) => (
                        <SingleReview key={i} person={node.Name} date={node.Date}>{node.Description}</SingleReview>
                    ))
                }
            </div>
        </div>
    )
}

export default ReviewSection